<template>
    <div>
        <div class="row no-gutters">
            <div class="col-xl-3 col-lg-4 order-2 order-lg-1">
                <section class="o-filter">
                    <b-button
                        v-b-toggle.modActivity
                        variant="primary"
                        class="d-block d-lg-none mt-1 mb-1 w-100"
                    >Modifica attività
                    </b-button>
                    <b-collapse visible accordion id="modActivity" class="mt-0 o-filter">
                        <div class="o-filter">
                            <div class="row no-gutter bg-info pt-5 pb-2">
                                <div class="col-12 pl-4 pr-4 w-100">
                                    <div class="d-flex justify-content-between">
                                        <div>
                                            <h4 class="text-primary font-weight-bold ml-0 pl-0">
                                                {{ getActivityById.activity.name }}
                                            </h4>
                                        </div>
                                        <div>
                                            <b-badge class="m-badge text-primary ml-2"
                                            ><span>{{
                                                    getActivityById.activity.activityType_Code
                                                }}</span></b-badge
                                            >
                                        </div>
                                    </div>

<!--                                    <b-form @submit="saveEditActivity">-->
                                    <form class="row pt-2 no-gutters" @submit.stop.prevent="saveEditActivity()">
                                        <b-form-group label="Nome*" label-for="name-input" class="m-input col-12">
                                            <b-form-input id="name-input" v-model="editActivity.name" maxlength="100" required></b-form-input>
                                        </b-form-group>

                                        <b-form-group
                                            label="Tipologia*"
                                            label-for="name-select"
                                            class="m-input col-12"
                                        >
                                            <b-form-select id="name-select" v-model="editActivity.activityType_Id" :options="typeActivity" value-field="activity.id" text-field="activity.name" required>
                                            </b-form-select>
                                        </b-form-group>

                                        <v-date-picker class="col-12 " v-model="editActivity.startDt">
                                            <template v-slot="{ inputValue, inputEvents }">
                                                <b-form-group label="Data inizio*" label-for="input-day" class="m-input ">
                                                    <input id="input-day" class="form-control" :value="inputValue" v-on="inputEvents" required />
                                                </b-form-group>
                                            </template>
                                        </v-date-picker>

<!--                                        <v-date-picker class="col-12 " v-model="editActivity.startDt">-->
<!--                                            <template v-slot="{ inputValue, togglePopover }">-->
<!--                                                <b-form-group label="Data inizio*" label-for="input-day" class="m-input ">-->
<!--                                                    <input id="input-day" class="form-control" :value="inputValue" @click="togglePopover" required />-->
<!--                                                </b-form-group>-->
<!--                                            </template>-->
<!--                                        </v-date-picker>-->

                                        <v-date-picker class="col-12" v-model="editActivity.endDt">
                                            <template v-slot="{ inputValue, inputEvents }">
                                                <b-form-group label="Data fine*" label-for="input-dayEnd" class="m-input">
                                                    <input id="input-dayEnd" class="form-control" :value="inputValue" v-on="inputEvents" required
                                                    />
                                                </b-form-group>
                                            </template>
                                        </v-date-picker>

<!--                                        <v-date-picker class="col-12" v-model="editActivity.endDt">-->
<!--                                            <template v-slot="{ inputValue, togglePopover }">-->
<!--                                                <b-form-group label="Data fine*" label-for="input-dayEnd" class="m-input">-->
<!--                                                    <input id="input-dayEnd" class="form-control" :value="inputValue" @click="togglePopover" required-->
<!--                                                    />-->
<!--                                                </b-form-group>-->
<!--                                            </template>-->
<!--                                        </v-date-picker>-->

                                        <div class="bg-light">
                                            <vue-editor v-model="editActivity.description"></vue-editor>
                                        </div>

                                        <b-form-group label="Tags" label-for="input-tags" class="m-input col-12 mt-3">
                                            <textarea id="input-tags" v-model="editActivity.tags" class="form-control"></textarea>
                                        </b-form-group>

                                        <v-date-picker class="col-12" v-model="editActivity.dueDt">
                                            <template v-slot="{ inputValue, inputEvents }">
                                                <b-form-group label="Data di scadenza per cancellazioni*" label-for="input-dueDt" class="m-input">
                                                    <input id="input-dueDt" class="form-control" :value="inputValue" v-on="inputEvents" required/>
                                                </b-form-group>
                                            </template>
                                        </v-date-picker>

<!--                                        <v-date-picker class="col-12" v-model="editActivity.dueDt">-->
<!--                                            <template v-slot="{ inputValue, togglePopover }">-->
<!--                                                <b-form-group label="Data di scadenza per cancellazioni*" label-for="input-dueDt" class="m-input">-->
<!--                                                    <input id="input-dueDt" class="form-control" :value="inputValue" @click="togglePopover" required/>-->
<!--                                                </b-form-group>-->
<!--                                            </template>-->
<!--                                        </v-date-picker>-->

                                        <b-form-group label="" label-for="sendMail-input" class="m-input col-12">
                                            <b-form-checkbox id="sendMail-input" v-model="editActivity.sendMail" class="">Invio mail?</b-form-checkbox>
                                        </b-form-group>

                                        <div v-if="formHasError" class="text-danger">Compila i campi obbligatori</div>

                                        <b-form-group label="Mittente mail*" label-for="mailFrom-input" class="m-input col-12">
                                            <b-form-input id="mailFrom-input" v-model="editActivity.mailFrom" maxlength="100" ></b-form-input>
                                        </b-form-group>

                                        <b-form-group label="Oggetto mail*" label-for="mailSubject-input" class="m-input col-12">
                                            <b-form-input id="mailSubject-input" v-model="editActivity.mailSubject" maxlength="100" ></b-form-input>
                                        </b-form-group>



                                        <div class="bg-light">
                                            <vue-editor v-model="editActivity.mailBody"></vue-editor>
                                        </div>
<!--                                        <b-form-group label="Corpo mail*" label-for="input-mailBody" class="m-input col-12 mt-3">-->
<!--                                            <textarea id="input-mailBody" v-model="editActivity.mailBody" class="form-control"></textarea>-->
<!--                                        </b-form-group>-->

                                        <b-button
                                            block
                                            type="submit"
                                            variant="primary"
                                            class=" mt-3 w-100"
                                        >Salva
                                        </b-button
                                        >
<!--                                    </b-form>-->
                                    </form>
                                </div>
                            </div>
                        </div>
                    </b-collapse>
                </section>
            </div>
            <div class="col-12 col-lg-8 col-xl-9 order-1 order-lg-2">
                <b-skeleton-wrapper :loading="loading">
                    <div class="col-12 pl-2 pr-2">
                        <b-skeleton
                            width="25%"
                            animation="wave"
                            class="mt-4 mb-4"
                        ></b-skeleton>

                        <b-skeleton-table
                            :rows="5"
                            :columns="4"
                            :table-props="{ bordered: true, striped: true }"
                        ></b-skeleton-table>
                    </div>
                </b-skeleton-wrapper>
                <section v-if="loading == true">
                    <div class="row no-gutters">
                        <div class="col-12 pl-2 pr-2 h-100">
                            <div class="row no-gutters pt-4 pb-2">
                                <div class="col-lg-6">
                                    <div class="row no-gutters align-items-center">
                                        <div class="col-xl-6">
                                            <p class="text-primary font-weight-bold ml-0 pl-0">
                                                {{ contact.length }} Contatti ricevuti
                                            </p>

                                            <p>
<!--                                                @change="updatePerPage"-->
                                                Numero risultati:
                                                <b-form-select
                                                    id="per-page-select"
                                                    v-model="perPage"
                                                    :options="pageOptions"
                                                    size="sm"
                                                    class="w-auto"
                                                ></b-form-select>
                                            </p>
                                        </div>
                                        <div class="col-xl-6">
                                            <b-form-input
                                                id="filter-input"
                                                v-model="filter"
                                                @keyup.enter="enterClickedFilter()"
                                                type="search"
                                                placeholder="Cerca"
                                                size="md"
                                            ></b-form-input>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-6  mt-2 mt-xl-0">
                                    <div
                                        class="d-flex justify-content-xl-end align-items-center h-100"
                                    >
                                        <b-button-group class="ml-2">
                                            <b-button variant="outline-primary" @click="exportResult">
                                                Esporta risultati
                                            </b-button
                                            >
                                            <b-button
                                                variant="outline-primary"
                                                v-b-modal.importContact
                                            >Importa contatti
                                            </b-button
                                            >
                                            <!-- b-button variant="primary" v-b-modal.newContact
                                              >Nuovo contatto</b-button
                                            > -->
                                        </b-button-group>
                                    </div>
                                </div>
                                <b-table
                                    :items="contact"
                                    :fields="fields"
                                    :current-page="currentPage"
                                    :per-page="perPage"
                                    stacked="md"
                                    show-empty
                                    small
                                    striped
                                    ref="table"
                                    id="ContactTable"
                                    thead-tr-class="m-table"
                                    sort-icon-left
                                >
                                    <template #cell(namecognome)="row">
                                        {{ row.item.person.firstName }}
                                        {{ row.item.person.lastName }}
                                    </template>

                                    <template #cell(actions)="row">
                                        <b-button
                                            size="sm"
                                            :to="{
                        name: 'DettagliContatto',
                        query: { id: row.item.id },
                      }"
                                            class="mr-1"
                                        >
                                            Dettagli
                                        </b-button>
                                        <b-button
                                            size="sm"
                                            variant="outline-primary"
                                            @click="ModalArchiveById(row.item.id)"
                                            class="mr-1 mt-1 mt-xl-0"
                                        >
                                            Archivia
                                        </b-button>
                                    </template>
                                </b-table>
                            </div>
                            <b-pagination
                                v-model="currentPage"
                                :total-rows="totalRows"
                                size="sm"
                                :per-page="perPage"
                                aria-controls="ContactTable"
                                align="center"
                            ></b-pagination>
                        </div>

                        <b-modal
                            id="newContact"
                            title="Nuovo contatto"
                            @hide="resetModal"
                            ref="modal"
                            centered
                            @ok="handleOk"
                            :ok-disabled="checkNewContact !== true"
                            ok-only
                            ok-title="Salva"
                            header-class="text-primary"
                            body-class="pb-0"
                            footer-class="d-flex justify-content-start border-0 pt-0"
                        >
                            <form ref="form" @submit.stop.prevent="saveLine()">
                                <b-form-group
                                    label="Nome"
                                    label-for="firstName"
                                    class="m-input"
                                >
                                    <b-form-input
                                        id="firstName"
                                        v-model="newContact.firstName"
                                        maxlength="100"
                                    ></b-form-input>
                                </b-form-group>

                                <b-form-group
                                    label="Cognome"
                                    label-for="lastName"
                                    class="m-input"
                                >
                                    <b-form-input
                                        id="lastName"
                                        v-model="newContact.lastName"
                                        maxlength="100"
                                    ></b-form-input>
                                </b-form-group>

                                <v-date-picker
                                    class=""
                                    v-model="newContact.birthDt"
                                    :masks="masks"
                                >
                                    <template v-slot="{ inputValue, togglePopover }">
                                        <b-form-group
                                            label="Data di nascita"
                                            label-for="birthDt"
                                            class="m-input"
                                        >
                                            <input
                                                id="birthDt"
                                                class="form-control"
                                                :value="inputValue"
                                                @click="togglePopover"
                                            />
                                        </b-form-group>
                                    </template>
                                </v-date-picker>

                                <b-form-group label="Email" label-for="email" class="m-input">
                                    <b-form-input
                                        id="email"
                                        v-model="newContact.email"
                                        type="email"
                                    ></b-form-input>
                                </b-form-group>

                                <b-form-group
                                    label="Telefono"
                                    label-for="phoneNumber"
                                    class="m-input"
                                >
                                    <b-form-input
                                        id="phoneNumber"
                                        type="tel"
                                        v-model="newContact.phoneNumber"
                                    ></b-form-input>
                                </b-form-group>
                            </form>
                        </b-modal>

                        <b-modal
                            id="importContact"
                            title="Importa Contatti"
                            @hide="resetAddContact"
                            ref="modal"
                            centered
                            @ok="handleOkContact"
                            ok-only
                            ok-title="Salva"
                            header-class="text-primary"
                            body-class="pb-0"
                            footer-class="d-flex justify-content-start border-0 pt-0"
                        >
                            <p>
                                <small>Vedi file

                                    <strong>
                                        <a href="../import_file_example.xlsx" target="_blank" download class="text-primary">
                                            modello (.xlsx)
                                        </a>
                                    </strong>
                                </small>
                            </p>
                            <hr/>
                            <form ref="form" @submit.stop.prevent="importContactAction()">
                                <b-form-group class="mb-5">
                                    <b-form-file
                                        v-model="importContact.file"
                                        placeholder="Scegli un file o Trascinalo qui..."
                                        drop-placeholder="Trascina il file qui..."
                                        accept=".csv, .xls, .xlsx"
                                    ></b-form-file>
                                </b-form-group>
                            </form>
                        </b-modal>

                        <b-modal
                            id="modal-archive"
                            @ok="archiveRow"
                            size="sm"
                            button-size="sm"
                            buttonSize="sm"
                            okVariant="danger"
                            okTitle="YES"
                            cancelTitle="NO"
                            footerClass="p-2"
                            hideHeaderClose
                            centered
                        >
                            <p><strong>Conferma archiviazione</strong></p>
                        </b-modal>
                    </div>
                </section>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment";
import {mapActions} from "vuex";
import apiAxios from "../libs/apiAxios";
import {VueEditor} from "vue2-editor";

export default {
    components: {
        VueEditor,
    },
    name: "Dettagli-attivita",
    data() {
        return {
            contact: [],
            loading: false,
            formHasError: false,
            fields: [
                {
                    key: "namecognome",
                    label: "Nome",
                    tdClass: "pl-3",
                    sortable: true,
                },
                {
                    key: "person.email",
                    label: "Email",
                    sortable: true,
                },
                {
                    key: "person.phoneNumber",
                    label: "Telefono",
                    sortable: true,
                },
                {
                    key: "actions",
                    label: "",
                    sortable: false,
                    thClass: "",
                    tdClass: "d-flex justify-content-end ",
                },
            ],

            editActivity: [],
            itemArchive: "",
            getActivityById: {
                activity: {
                    name: "",
                    activityType_Code: "",
                },
            },

            masks: {
                input: "DD/MM/YYYY",
                output: "DD/MM/YYYY",
            },

            sortBy: "Ord",
            sortDesc: false,
            totalRows: 1,
            currentPage: 1,
            perPage: 50,
            pageOptions: [10, 20, 50, 100, 300, 500],
            filter: null,
            typeActivity: [],

            importContact: {
                activityImportCode: null,
                file: null,
            },

            baseContact: {
                id: null,
                firstName: "",
                lastName: "",
                birthDt: "",
                email: "",
                phoneNumber: "",
            },

            newContact: {
                id: null,
                firstName: "",
                lastName: "",
                birthDt: "",
                email: "",
                phoneNumber: "",
            },
        };
    },

    created() {
        this.listTypeActivity();
        this.activityById();
        this.listContact(0);
        this.nrItem();
    },

    computed: {
        /*     activityById() {
        const getActivity = this.$store.getters["activity/activityById"](
        this.$route.query.id
        );
        let activity = "";
        if (getActivity !== undefined) {
        activity = getActivity;
        }

        return activity;
        },
        */
        state() {
            return this.$store.state;
        },

        // numberPerPage() {
        //     return this.$store.getters["contact/perPage"];
        // },

        checkNewContact() {
            if (this.newContact.firstName != "" && this.newContact.lastName != "" && this.newContact.birthDt != "") {
                return true;
            } else {
                return false;
            }
        },
    },

    methods: {
        ...mapActions([
            "contact/getContactsByActivitiesPage",
            "activity/getActivity",
        ]),

        async listTypeActivity() {
            try {
                await this.$store.dispatch("typeActivity/getTypeActivity").then(() => {
                    this.typeActivity = this.$store.getters["typeActivity/listActivity"];
                    /* console.log(this.typeActivity); */
                    return this.typeActivity;
                });
            } catch (e) {
                console.log({message: "Some error getActivity"});
            }
        },

        async listContact($currentPage) {
            try {
                await this.$store
                    .dispatch("contact/getContactsByActivitiesPage", {
                        id: this.$route.query.id,
                        perPage:500,
                        nrCurrentPage: $currentPage,
                        search: this.filter,
                    })
                    .then(() => {
                        this.contact = this.$store.getters["contact/contact"];
                        this.nrItem();
                        this.loading = true;
                        return this.contact;
                    });
            } catch (e) {
                this.loading = false;
                console.log({message: "Some error listContact"});
            }
        },

        async nrItem() {
            this.totalRows = this.$store.getters["contact/nrItem"];
            return this.totalRows;
        },

        async activityById() {
            try {
                await this.$store.dispatch("activity/getActivity").then(() => {
                    console.log("activity");
                    /*   console.log(this.$route.query.id); */

                    /* this.getActivity = this.$store.getters["activity/activity"]; */

                    this.getActivityById = this.$store.getters["activity/activityById"](
                        this.$route.query.id
                    );
                    /*   console.log(this.getActivityById); */

                    this.editActivity = {
                        id: this.getActivityById.activity.id,
                        activityType_Id: this.getActivityById.activity.activityType_Id,
                        code: this.getActivityById.activity.code,
                        name: this.getActivityById.activity.name,
                        description: this.getActivityById.activity.description,
                        startDt: moment.utc(String(this.getActivityById.activity.startDt), "YYYY-MM-DD").format(),
                        endDt: moment.utc(String(this.getActivityById.activity.endDt), "YYYY-MM-DD").format(),
                        dueDt: moment.utc(String(this.getActivityById.activity.dueDt), "YYYY-MM-DD").format(),
                        tags: this.getActivityById.activity.tags,
                        sorting: this.getActivityById.activity.sorting,
                        importcode: this.getActivityById.activity.ImportCode,
                        sendMail: this.getActivityById.activity.sendMail,
                        mailFrom: this.getActivityById.activity.mailFrom,
                        mailSubject: this.getActivityById.activity.mailSubject,
                        mailBody: this.getActivityById.activity.mailBody,
                    };
                    return;
                });
            } catch (e) {
                console.log({message: "Some error getActivity"});
            }
        },

        enterClickedFilter() {
            this.listContact(0);
        },

        saveEditActivity() {
            this.formHasError = false;

            if(this.editActivity.sendMail){
                if(!this.editActivity.mailFrom || !this.editActivity.mailSubject || !this.editActivity.mailBody){
                    this.formHasError = true;
                    return;
                }
            }

            var data = {
                id: this.$route.query.id,
                activityType_Id: parseInt(this.editActivity.activityType_Id),
                code: this.editActivity.code,
                name: this.editActivity.name,
                description: this.editActivity.description,
                startDt: moment(String(this.editActivity.startDt)).format(),
                endDt: moment(String(this.editActivity.endDt)).format(),
                dueDt: moment(String(this.editActivity.dueDt)).format(),
                tags: this.editActivity.tags,
                sendMail: this.editActivity.sendMail,
                mailFrom: this.editActivity.mailFrom,
                mailSubject: this.editActivity.mailSubject,
                mailBody: this.editActivity.mailBody,
                sorting: this.editActivity.sorting,
            };
            // console.log(data);
            this.$store.dispatch("activity/putActivty", data);
        },

        chageFormatDate($date) {
            return moment.utc(new Date($date), "YYYY-MM-DD").format("DD/MM/YYYY");
        },

        async exportResult() {
            const complexFilters = `&complexFilters[]=${this.activityById.activity.activityType_Id};${this.$route.query.id};pos`;
            const $search = this.filter;

            if ($search != "" && $search != null && complexFilters != "") {
                console.log(
                    `api/foppacrm/Contacts/spreadsheet?includeArchived=false&searchText=${$search}${complexFilters}`
                );
                await apiAxios
                    .get(
                        `api/foppacrm/Contacts/spreadsheet?includeArchived=false&searchText=${$search}${complexFilters}`,
                        {
                            responseType: "blob",
                        }
                    )
                    .then((response) => {
                        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                        var fileLink = document.createElement("a");

                        fileLink.href = fileURL;
                        fileLink.setAttribute("download", "export_contact.xlsx");
                        document.body.appendChild(fileLink);

                        fileLink.click();
                    });
            } else if ($search == "" || ($search == null && complexFilters != "")) {
                console.log(
                    `api/foppacrm/Contacts/spreadsheet?includeArchived=false${complexFilters}`
                );
                await apiAxios
                    .get(
                        `api/foppacrm/Contacts/spreadsheet?includeArchived=false${complexFilters}`,
                        {
                            responseType: "blob",
                        }
                    )
                    .then((response) => {
                        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                        var fileLink = document.createElement("a");

                        fileLink.href = fileURL;
                        fileLink.setAttribute("download", "export_contact.xlsx");
                        document.body.appendChild(fileLink);

                        fileLink.click();
                    });
            }
        },

        ModalArchiveById($id) {
            this.$bvModal.show("modal-archive");
            this.itemArchive = $id;
        },

        async archiveRow() {
            const $id = this.itemArchive;
            console.log($id);
            try {
                await this.$store
                    .dispatch("contact/archiveSingleContacts", {
                        idContact: $id,
                    })
                    .then(() => {
                        this.contact = this.$store.getters["contact/contact"];
                        console.log("archiveRow");
                        console.log(this.contact);
                        this.itemArchive = "";
                        this.listContact(0);
                        return this.contact;
                    });
            } catch (e) {
                console.log({message: "Some error archiveSingleContacts"});
            }
        },

        handleOkContact(bvModalEvt) {
            bvModalEvt.preventDefault();
            this.importContactAction();
        },

        resetAddContact() {
            console.log("resetAddContact");
        },

        async importContactAction() {
            /* console.log("importContact");
            console.log(this.activityById.activity.importCode); */
            let formData = new FormData();
            formData.append(
                "activityImportCode",
                this.activityById.activity.importCode
            );
            formData.append("importFile", this.importContact.file);

            apiAxios
                .post(`api/foppacrm/contactsimports/fromxls`, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((response) => {
                    if (response.data.isSuccess == true) {
                        this.listContact(0);
                        this.$bvModal.hide("importContact");
                    } else {
                        console.log(response);
                    }
                })
                .catch((error) => console.log(error));
        },

        saveLine() {
            var data = {
                firstName: this.newContact.firstName,
                lastName: this.newContact.lastName,
                birthDt: moment(String(this.newContact.birthDt)).format(),
                email: this.newContact.email,
                phoneNumber: this.newContact.phoneNumber,
                activityType_Code: this.editActivity.code,
            };
            console.log(data);

            this.$store.dispatch("contact/postContact", data);

            this.$nextTick(() => {
                this.$bvModal.hide("newContact");
            });
        },

        resetModal() {
            this.newActivity = {...this.baseContact};
            this.listActivity = [];
        },

        handleOk(bvModalEvt) {
            bvModalEvt.preventDefault();
            this.saveLine();
        },
    },

    /* watch: {
    currentPage(newPage) {
    console.log("watch");
    console.log(newPage);

    this.listContact(newPage);

    },
    }, */
};
</script>
